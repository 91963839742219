import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const GlobalStyle = createGlobalStyle`


    *{
        margin: 0;
        padding: 0;
        font-family: 'HelveticaNeueLTPro', sans-serif;
        color: ${colors.dark};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
        /* @media (min-width: 1440px) {
            font-size: 0.0695vw;
            } */
    }
    .CookieConsent{

        @media (max-width: ${mediaQuery.laptop}) {
    margin: 0 ${distances.pageMarginBig / 2}rem  !important;
    width: calc(100% - ${distances.pageMarginBig}rem )  !important;
   bottom: 20rem  !important;
   padding: 0 !important;
   padding-bottom: 12rem !important;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 0 15rem  !important;
    width: calc(100% - 30rem ) !important;

    bottom: 15rem  !important;
  }

  .markdownP{
    font-size: 12rem !important;
 
  }
.cookieText{
  @media (max-width: ${mediaQuery.tablet}) {

width: 100% !important;
}
}

  .markdownP a{
    font-size: inherit;
    font-weight: 500;
  }
        
    }
    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

    /* html{scroll-behavior: smooth;} */
    ::selection {
        color: ${colors.white};
        background: ${colors.dark};
;
}
html {width: 100%; } /* Force 'html' to be viewport width */

 body{
     overflow-x: hidden;
     width: 100vw;
     font-size:  16rem ; 
 }
 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
